import { css, useTheme } from '@emotion/react'
import React, { HTMLAttributes } from 'react'

export interface SeparatorProps extends HTMLAttributes<HTMLDivElement> {}

export function Separator(props: SeparatorProps) {
  return (
    <div
      css={css`
        width: 100%;
        height: 1px;
        background: currentColor;
      `}
      {...props}
    />
  )
}
