import { css, useTheme } from '@emotion/react'
import React, { useState } from 'react'
import {
  FormInput,
  FormCofirm,
  FormComplete,
  FormError,
} from '@/components/contact-form'
import { FormProvider, useForm } from 'react-hook-form'
import { useDidUpdateEffect } from '@/hooks/use-did-update-effect'
import axios from 'axios'
import { navigate } from 'gatsby'
import { Container } from '@/components/container'
import { Heading } from '@/components/heading'
import { FadeIn } from '@/components/fade-in'
import { PageHeader } from '@/components/page-header'

interface ContactFormData {
  companyName: string
  contents: string
  email: string
  fullName: string
  kind: string
  pref: string
  tel: string
}

enum FormProgress {
  Input,
  Confirm,
  Complete,
  Error,
}

const URL = '/api/send-mail'

export default function ContactPage() {
  const methods = useForm<ContactFormData>()
  const [progress, setProgress] = useState(FormProgress.Input)
  const theme = useTheme()

  useDidUpdateEffect(() => {
    navigate('#top')
  }, [progress])

  const onSubmit = async (data: ContactFormData) => {
    switch (progress) {
      case FormProgress.Input:
        setProgress(FormProgress.Confirm)
        break
      case FormProgress.Confirm:
        try {
          const postData = Object.entries(data).reduce((prev, [k, v]) => {
            return {
              ...prev,
              [k]: encodeURIComponent(v?.replace(/\r?\n/g, '\n') ?? ''),
            }
          }, {})

          // await axios({
          //   method: 'post',
          //   url: URL,
          //   headers: {
          //     'Content-Type': 'application/json;charset=utf-8',
          //   },
          //   data: postData,
          // })

          setProgress(FormProgress.Complete)
        } catch (err) {
          console.log((err as Error).message)
          setProgress(FormProgress.Error)
        }
        break
      case FormProgress.Complete:
      case FormProgress.Error:
        break
    }
  }

  return (
    <FadeIn isShow>
      <section id="top">
        <PageHeader>質問・お問合せ</PageHeader>

        <div
          css={css`
            padding: 11rem 4rem 19rem;
          `}
        >
          <Container>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {progress === FormProgress.Input ? (
                  <FormInput />
                ) : progress === FormProgress.Confirm ? (
                  <FormCofirm
                    onClickBackButton={() => {
                      setProgress(FormProgress.Input)
                    }}
                  />
                ) : progress === FormProgress.Complete ? (
                  <FormComplete />
                ) : (
                  <FormError
                    onClickBackButton={() => {
                      setProgress(FormProgress.Input)
                    }}
                  />
                )}
              </form>
            </FormProvider>
          </Container>
        </div>
      </section>
    </FadeIn>
  )
}
