import {
  DependencyList,
  EffectCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'

export function useDidUpdateEffect(
  effect: EffectCallback,
  deps?: DependencyList,
  isLayout?: boolean
) {
  const didMountRef = useRef(false)
  const callback = () => {
    if (!didMountRef.current) {
      didMountRef.current = true
    } else {
      return effect()
    }
  }

  if (isLayout) {
    useLayoutEffect(callback, deps)
  } else {
    useEffect(callback, deps)
  }
}
